import * as React from 'react';
import { GetServerSidePropsContext } from 'next';
import {
    // ContentBlock,
    Maybe,
    Post,
    RootQueryToCategoryConnection,
    Scalars,
} from 'interfaces/bumble-bff-landing-microsite-cms';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import PageTemplate from 'components/page-template/page-template';
import { PageTemplateContentType } from 'components/page-template/page-template-content';
import { getGeoCountry } from 'lib/get-geo-country';
import { basePathIndexUrl } from 'lib/schemes';
import { setupLangAndUrlParams } from 'lib/set-url-params';
import { PageCategoriesList } from 'components/more-category-posts/more-category-posts';
import { getRecentFriendshipCategoryPosts } from 'lib/api';
import lexemes from 'components/lexemes/bff/lexemes';
import { trackClick } from 'lib/api/track-click';

export type PageProps = {
    id?: Scalars['Int'];
    lang: SupportedLanguage;
    title: string;
    geoIpCountry?: string | null;
    // blocks: ContentBlock[];
    description?: string;
    categories?: Maybe<RootQueryToCategoryConnection>;
    pageCategoriesList?: PageCategoriesList;
    imageUrl?: string | null;
    imageAlt?: string | null;
    imageHeight?: string | null;
    imageWidth?: string | null;
    seoTitle?: string | null;
    seoDescription?: string | null;
    // datePublished?: string;
    // dateModified?: string;
    // @TODO: add support in CMS
    metaKeywords?: string | null;
    metaRobotsNoindex?: string | null;
    metaRobotsNofollow?: string | null;
    opengraphPublishedTime?: string | null;
    opengraphModifiedTime?: string | null;
    type: PageTemplateContentType;
    pageUrl?: string;
    footerCtaText?: Maybe<Scalars['String']>;
    footerCtaUrl?: Maybe<Scalars['String']>;
    posts?: Maybe<Maybe<Post>[]> | undefined;
};

export default function Page(props: PageProps) {
    const {
        id,
        lang,
        type,
        title,
        description,
        // blocks,
        geoIpCountry,
        imageUrl,
        imageAlt,
        imageHeight,
        imageWidth,
        seoTitle,
        seoDescription,
        metaKeywords,
        metaRobotsNoindex,
        metaRobotsNofollow,
        opengraphPublishedTime,
        opengraphModifiedTime,
        categories,
        pageCategoriesList,
        footerCtaText,
        footerCtaUrl,
        pageUrl,
        posts,
    } = props;

    return (
        <PageTemplate
            id={id}
            lang={lang}
            type={type}
            // @TODO: add other seo data later: title, text, images
            browserTab={seoTitle || lexemes.lxbffLandingSEOTitle}
            title={title}
            seoTitle={seoTitle || title}
            seoDescription={seoDescription || description}
            metaKeywords={metaKeywords}
            metaRobotsNoindex={metaRobotsNoindex}
            metaRobotsNofollow={metaRobotsNofollow}
            openTitle={seoTitle || title}
            openDescription={seoDescription || description}
            opengraphPublishedTime={opengraphPublishedTime}
            opengraphModifiedTime={opengraphModifiedTime}
            openImageUrl={imageUrl}
            openImageAlt={imageAlt}
            openImageHeight={imageHeight}
            openImageWidth={imageWidth}
            heroImageUrl={imageUrl}
            heroImageAlt={imageAlt}
            heroImageHeight={imageHeight}
            heroImageWidth={imageWidth}
            dynamicFooter={false}
            geoIpCountry={geoIpCountry}
            categories={categories}
            pageCategoriesList={pageCategoriesList || undefined}
            // @TODO: change props
            ctaButtonText={footerCtaText}
            ctaButtonUrl={footerCtaUrl}
            pageUrl={pageUrl}
            posts={posts}
        />
    );
}

export type GetServerSidePropsType = GetServerSidePropsContext<{ lang: SupportedLanguage }> & {
    locale?: SupportedLanguage;
};

export const getServerSideProps = async function ({
    query,
    locale,
    res,
    req,
}: GetServerSidePropsType) {
    /**
     * Temporary fix
     * trying to avoid too many unnessary requests
     */
    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

    const { lang, url } = await setupLangAndUrlParams(query, locale);

    const pageProps = { title: 'title', lang };
    // @ts-ignore
    pageProps.type = PageTemplate.type.Index;
    // @ts-ignore
    pageProps.pageUrl = `${basePathIndexUrl}${url}`;

    /**
     * if not index / category / article - we return not-found
     */
    if (!pageProps.title) {
        return {
            notFound: true,
        };
    }

    trackClick({ req, lang });

    try {
        const { posts } = await getRecentFriendshipCategoryPosts();
        // @ts-ignore
        pageProps.posts = posts;
    } catch (err) {
        console.error('getRecentFriendshipCategoryPosts failed', err);
    }

    return {
        props: {
            geoIpCountry: getGeoCountry(req),
            ...pageProps,
        },
    };
};
