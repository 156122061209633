import * as React from 'react';
import classNames from 'classnames';
import LoaderLogo from './loader-logo.svg';

export enum LoaderSize {
    SMALL,
    MEDIUM,
    LARGE,
}

export enum LoaderColor {
    PRIMARY,
}

export enum LoaderShape {
    DOTS,
    LOGO,
    JUMPING_DOTS,
}

const mapSizeToClassName: { [key in LoaderSize]: string } = {
    [LoaderSize.SMALL]: 'loader--size-s',
    [LoaderSize.MEDIUM]: 'loader--size-m',
    [LoaderSize.LARGE]: 'loader--size-l',
};

const mapColorToClassName: { [key in LoaderColor]: string } = {
    [LoaderColor.PRIMARY]: 'loader--primary',
};

const mapShapeToClassName: { [key in LoaderShape]: string } = {
    [LoaderShape.DOTS]: 'loader--dots',
    [LoaderShape.LOGO]: 'loader--logo',
    [LoaderShape.JUMPING_DOTS]: 'loader--jumping-dots',
};

type LoaderProps = {
    size?: LoaderSize;
    color?: LoaderColor;
    shape?: LoaderShape;
    isAnimated?: boolean;
    extraClass?: string;
    jsClass?: string;
};

const Loader: React.FunctionComponent<LoaderProps> = (props) => {
    const {
        size = LoaderSize.MEDIUM,
        color,
        shape = LoaderShape.DOTS,
        isAnimated = true,
        extraClass,
        jsClass,
    } = props;
    const classnames = {
        block: classNames(
            {
                loader: true,
                [mapSizeToClassName[size]]: true,
                [mapShapeToClassName[shape]]: true,
                [extraClass as string]: extraClass,
                [jsClass as string]: jsClass,
                'is-animated': isAnimated,
            },
            typeof color !== 'undefined' && mapColorToClassName[color]
        ),
    };

    // TODO: Make svg-animations instead of css
    return (
        <div className={classnames.block}>
            {shape === LoaderShape.LOGO ? <LoaderLogo /> : null}

            {shape !== LoaderShape.LOGO ? <div className="loader__inner" /> : null}
        </div>
    );
};

export default Loader;
