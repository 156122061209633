import React from 'react';

import Image from 'next/image';
import { ExternalLink } from 'lib/app/external-link';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';

type AppstoreLinksProps = {
    language?: SupportedLanguage;
};

export function AppstoreLinks(props: AppstoreLinksProps) {
    return (
        <div className="appstore-links">
            <a
                className="appstore-links__item appstore-links__item--appstore"
                href={ExternalLink.APP_STORE_IOS}
                aria-label="App Store"
            >
                <Image
                    src={`/bff/assets/${props.language || 'en'}/appstore-badge.svg`}
                    unoptimized={true}
                    width={120}
                    height={40}
                    alt="App Store"
                />
            </a>

            <a
                className={`appstore-links__item appstore-links__item--googleplay appstore-links__item ${
                    `appstore-links__item--googleplay-${props.language}` || ''
                }`}
                href={ExternalLink.APP_STORE_ANDROID}
                aria-label="Google Play"
            >
                <Image
                    src={`/bff/assets/${props.language || 'en'}/google-play-badge.png`}
                    width={155}
                    height={60}
                    alt="Google Play"
                />
            </a>
        </div>
    );
}
