import React from 'react';
import Icon from 'components/icon/icon';
import { socialNetworkLinks } from 'components/social-links/social-network-links';
import Image from 'lib/image-container';
import { BFF_HELP_CENTER_LINKS, BFF_MENU_LINKS } from 'lib/constants';
import lexemes from 'components/lexemes/bff/lexemes';
import lexemesFooter from 'components/lexemes/footer/lexemes';

function FooterLegacy() {
    return (
        <>
            <div className="layout__content">
                <div className="layout__instagram-feed">
                    <div className="instagram-feed">
                        <h2 className="instagram-feed__title">
                            <a href={socialNetworkLinks.Instagram}>
                                {lexemes.lxbffLandingSectionFiveColumnRightCta}
                            </a>
                        </h2>
                    </div>
                </div>
                <div className="layout__cta" data-modal-container="js-form-download-app"></div>
            </div>
            <div className="layout__content">
                <div className="trend-of-the-year">
                    <a
                        className="trend-of-the-year__link"
                        href="http://apple.co/ASA2021-Winners"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Image
                            alt={lexemes.lxbffLandingTrendAltLabel}
                            className="trend-of-the-year__image js-card-image"
                            src="/bff/assets/ASA-Trend-Of-The-Year-2021@2x.png"
                            layout="fill"
                            objectFit="fill"
                        />
                    </a>
                </div>
            </div>

            <div className="layout__footer">
                <div className="footer">
                    <div className="footer__inner">
                        <div className="footer__sections">
                            <div className="footer__section footer__section--social">
                                <div className="footer__logo">
                                    <a href="/en/" className="logo" title="Home">
                                        <Icon isNative={true} name={Icon.Name.Logo} />
                                    </a>
                                </div>
                                <div className="footer__accounts">
                                    <ul className="social-links">
                                        <li className="social-links__item">
                                            <a
                                                href={socialNetworkLinks.Instagram}
                                                className="social-links__link"
                                            >
                                                <span className="a11y-visually-hidden">
                                                    {socialNetworkLinks.Instagram}
                                                </span>
                                                <Icon
                                                    isNative={true}
                                                    name={Icon.Name.InstagramInner}
                                                />
                                            </a>
                                        </li>
                                        {/* <li className="social-links__item">
                                            <a
                                                href={socialNetworkLinks.Facebook}
                                                className="social-links__link"
                                            >
                                                <span className="a11y-visually-hidden">
                                                    {socialNetworkLinks.Facebook}
                                                </span>
                                                <Icon
                                                    isNative={true}
                                                    name={Icon.Name.FacebookSquare}
                                                />
                                            </a>
                                        </li> */}
                                        <li className="social-links__item">
                                            <a
                                                href={socialNetworkLinks.Twitter}
                                                className="social-links__link"
                                            >
                                                <span className="a11y-visually-hidden">
                                                    {socialNetworkLinks.Twitter}
                                                </span>
                                                <Icon isNative={true} name={Icon.Name.Twitter} />
                                            </a>
                                        </li>
                                        <li className="social-links__item">
                                            <a
                                                href={socialNetworkLinks.Pinterest}
                                                className="social-links__link"
                                            >
                                                <span className="a11y-visually-hidden">
                                                    {socialNetworkLinks.Pinterest}
                                                </span>
                                                <Icon isNative={true} name={Icon.Name.Pinterest} />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <nav
                                className="footer__section footer__section--main"
                                aria-labelledby="footer-label"
                            >
                                <ul className="footer-menu">
                                    <li className="footer-menu__item ">
                                        <a
                                            href={BFF_HELP_CENTER_LINKS.INDEX}
                                            className="footer-menu__link"
                                        >
                                            {lexemesFooter.lxFooterLinksFAQ}
                                        </a>
                                    </li>
                                    <li className="footer-menu__item ">
                                        <a
                                            href="https://bumble.events"
                                            className="footer-menu__link"
                                        >
                                            {lexemesFooter.lxFooterLinksEvents}
                                        </a>
                                    </li>
                                    <li className="footer-menu__item ">
                                        <a
                                            href={BFF_MENU_LINKS.CONTACT_US}
                                            className="footer-menu__link"
                                        >
                                            {lexemesFooter.lxFooterLinksContacts}
                                        </a>
                                    </li>
                                    <li className="footer-menu__item ">
                                        <a
                                            href={BFF_MENU_LINKS.GUIDELINES}
                                            className="footer-menu__link"
                                        >
                                            {lexemesFooter.lxFooterLinksGuidelines}
                                        </a>
                                    </li>
                                    <li className="footer-menu__item ">
                                        <a
                                            href="http://team.bumble.com/"
                                            className="footer-menu__link"
                                        >
                                            {lexemesFooter.lxFooterLinksCareers}
                                        </a>
                                    </li>
                                    <li className="footer-menu__item">
                                        <a
                                            href="https://ir.bumble.com"
                                            className="footer-menu__link"
                                        >
                                            Investors
                                        </a>
                                    </li>
                                    <li className="footer-menu__item">
                                        <a
                                            href="https://bumbcdn.com/big/documents/bumble/statement-on-modern-slavery-act.pdf"
                                            className="footer-menu__link"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {lexemesFooter.lxFooterLinksSlaveryAct}
                                        </a>
                                    </li>
                                    <li className="footer-menu__item ">
                                        <a
                                            href={BFF_MENU_LINKS.TERMS}
                                            className="footer-menu__link"
                                        >
                                            {lexemesFooter.lxFooterLinksTerms}
                                        </a>
                                    </li>
                                    <li className="footer-menu__item ">
                                        <a
                                            href={BFF_MENU_LINKS.PRIVACY}
                                            className="footer-menu__link"
                                        >
                                            {lexemesFooter.lxFooterLinksPrivacy}
                                        </a>
                                    </li>
                                    <li className="footer-menu__item is-hidden ">
                                        <a href="/en/impressum" className="footer-menu__link">
                                            Impressum
                                        </a>
                                    </li>
                                    <li className="footer-menu__item ">
                                        <button
                                            type="button"
                                            className="footer-menu__link js-new-cookie-policy js-manage-cookies"
                                            id="gdpr_link"
                                        >
                                            {lexemesFooter.lxFooterLinksCookie}
                                        </button>
                                    </li>
                                    <li className="footer-menu__item ">
                                        <a href={BFF_MENU_LINKS.BLOG} className="footer-menu__link">
                                            {lexemes.lxbffLandingSectionFiveColumnRightTitle}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="footer__caption">
                            <div className="footer__caption-copyright">
                                © 2023 Bumble | All Rights Reserved
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FooterLegacy;
