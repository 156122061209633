import * as React from 'react';
import Text, { TextType, TextProps } from 'components/typography/text';

const P3: React.FC<TextProps> = (props) => (
    <Text type={TextType.P3} isMedium={props.isMedium} {...props}>
        {props.children}
    </Text>
);

export default P3;
