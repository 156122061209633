import * as React from 'react';
import cx from 'classnames';

type FormFieldProps = {
    type?: 'text' | 'email';
    label?: string;
    error?: string;
    value: string;
    name: string;
    onChange: (...args: any[]) => any;
    hasError?: boolean;
    required?: boolean;
    placeholder?: string;
    extraClass?: string;
    jsClass?: string;
};

function FormField(props: FormFieldProps) {
    const {
        type = 'text',
        label,
        error,
        value,
        name,
        onChange,
        placeholder,
        hasError,
        required,
        extraClass,
        jsClass,
    } = props;

    const className = {
        block: cx({
            'form-field': true,
            'trigger-input-text': true,
            'is-invalid': hasError,
            [extraClass as string]: extraClass,
            [jsClass as string]: jsClass,
        }),
    };

    return (
        <div className={className.block}>
            <div className="form-field__input">
                <input
                    type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    required={required}
                />
            </div>
            {label ? <label className="form-field__label">{label}</label> : null}
            <div className="form-field__error">{error}</div>
        </div>
    );
}

export default FormField;
