import * as React from 'react';
import Text, { TextType, TextProps } from 'components/typography/text';

const Caption: React.FC<TextProps> = (props) => (
    <Text
        type={TextType.CAPTION}
        isMedium={props.isMedium}
        isExtended={props.isExtended}
        isResponsive={props.isResponsive || true}
        {...props}
    >
        {props.children}
    </Text>
);

export default Caption;
