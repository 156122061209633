import * as React from 'react';
import SocialLinks from 'components/social-links/social-links';
import Icon from 'components/icon/icon';
import {
    socialNetworkLinks,
    SocialNetworkProfileLinks,
} from 'components/social-links/social-network-links';
import Link from 'components/link/link';
import { P1 } from 'components/typography';

type SocialSharingBlockProps = {
    title?: string;
};

export function SocialSharingBlock(props: SocialSharingBlockProps) {
    return (
        <div className="social-sharing-block">
            <div className="social-sharing-block__title">
                <Link href={socialNetworkLinks.Instagram} target="_blank">
                    <P1 html={props.title} />
                </Link>
            </div>
            <div className="social-sharing-block__list">
                <SocialLinks
                    isBFF={true}
                    items={[
                        {
                            type: 'button--bff instagram',
                            icon: <Icon size="md" name={Icon.Name.Instagram} />,
                            button: true,
                            url: SocialNetworkProfileLinks.Instagram,
                            labelText: 'Instagram',
                        },
                        {
                            type: 'button--bff tiktok',
                            icon: <Icon size="md" name={Icon.Name.TikTok} />,
                            button: true,
                            url: SocialNetworkProfileLinks.TikTok,
                            labelText: 'Tik Tok',
                        },
                        // {
                        //     type: 'button--bff facebook',
                        //     icon: <Icon size="md" name={Icon.Name.FacebookSquare} />,
                        //     button: true,
                        //     url: SocialNetworkProfileLinks.Facebook,
                        //     labelText: 'Facebook',
                        // },
                    ]}
                />
            </div>
        </div>
    );
}
