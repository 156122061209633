import * as React from 'react';
import classNames from 'classnames';

export enum TextType {
    TITLE = 'title',
    SUBTITLE = 'subtitle',
    CAPTION = 'caption',
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    P1 = 'p-1',
    P2 = 'p-2',
    P3 = 'p-3',
}

export enum TextTagName {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
}

export type TextProps = {
    children?: React.ReactNode;
    tagname?: TextTagName;
    inline?: boolean;
    type?: TextType;
    isMedium?: boolean;
    noWrap?: boolean;
    isExtended?: boolean;
    isInherited?: boolean;
    extraClass?: string;
    color?: 'white' | 'grey';
    style?: any;
    html?: string;
    isResponsive?: boolean;
};

const Text: React.FC<TextProps> = (props) => {
    const {
        tagname,
        inline,
        type,
        color,
        isMedium,
        noWrap,
        isExtended,
        isInherited,
        extraClass,
        style,
        html,
        isResponsive,
    } = props;
    const classnames = {
        block: classNames(
            typeof type !== 'undefined' && type,
            {
                'is-medium': isMedium,
                'has-no-wrap': noWrap,
                'is-extended': isExtended,
                'is-inherited': isInherited,
                [extraClass as string]: extraClass,
                'is-responsive': isResponsive,
            },
            color && typeof color === 'string' ? color : null
        ),
    };
    let textTagName = inline ? 'span' : 'div';

    if (tagname) {
        textTagName = tagname;
    }

    return html
        ? React.createElement(textTagName, {
              className: classnames.block,
              style,
              dangerouslySetInnerHTML: {
                  __html: html,
              },
          })
        : React.createElement(
              textTagName,
              {
                  className: classnames.block,
                  style,
              },
              props.children
          );
};

export default Text;
