import ReactGA from 'react-ga4';
import { log } from 'lib/log';
import { GOOGLE_ANALYTICS_ACCOUNT } from 'lib/constants';

export const GA4_ID = GOOGLE_ANALYTICS_ACCOUNT;

export function initialize() {
    /**
     * It is set in GTM-PKDNCF9
     * @TODO: fix options in GTM for GA4
     */
    // ReactGA.initialize(GA4_ID);
    // ReactGA.set({ anonymizeIp: true, cookieExpires: 31536000, testMode: true });
}

export function pageView() {
    const page = window.location.pathname + window.location.search;

    log(`page view => ${page}`);

    /**
     * Technically we send hits via GTM
     */
    // ReactGA.send({ hitType: 'pageview', page });
}

export function clickEventFromElement(event: React.MouseEvent<HTMLAnchorElement>) {
    const {
        seoCategory = '',
        seoAction = '',
        seoLabel = '',
        seoValue,
        nonInteraction = false,
    } = event.currentTarget.dataset;

    const eventData = {
        category: seoCategory,
        action: seoAction,
        label: seoLabel,
        value:
            seoValue && typeof Number(parseInt(seoValue, 10)) === 'number'
                ? parseInt(seoValue, 10)
                : 0,
        nonInteraction: Boolean(nonInteraction),
    };

    log(`Click Event data => ${JSON.stringify(eventData)}`);

    ReactGA.event(eventData);
}

export function clickEventfromOptions({
    seoCategory = '',
    seoAction = '',
    seoLabel = '',
    seoValue = '',
    nonInteraction = false,
}) {
    const eventData = {
        category: seoCategory,
        action: seoAction,
        label: seoLabel,
        value:
            seoValue && typeof Number(parseInt(seoValue, 10)) === 'number'
                ? parseInt(seoValue, 10)
                : 0,
        nonInteraction: Boolean(nonInteraction),
    };

    log(`Click Event data => ${JSON.stringify(eventData)}`);

    ReactGA.event(eventData);
}
