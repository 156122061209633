import * as React from 'react';

export enum AppStoreBrands {
    AppStore = 'AppStore',
    GooglePlay = 'GooglePlay',
}

export enum AppLinksBumble {
    AppStore = 'https://app.appsflyer.com/id930441707',
    GooglePlay = 'https://app.appsflyer.com/com.bumble.app',
}

export enum AppLinksBumbleBff {
    // @TODO - update to BumbleBff app
    AppStore = 'https://app.appsflyer.com/id930441707',
    GooglePlay = 'https://play.google.com/store/apps/details?id=com.bumblebff.app',
}

export function setLinkParams(link: string) {
    return link.concat('?pid=bff_landing_self&c=bff_landing_index');
}

const DEFAULT_URL = process.env.NEXT_PUBLIC_CTA_URL || 'https://bff.app/u/3FoBxBt';

type setAppMarketUrlProps = {
    geoIpCountry?: string | null;
};

export function useAppMarketUrl(_props: setAppMarketUrlProps) {
    const url = React.useRef(DEFAULT_URL);

    return setLinkParams(url.current);
}
