import * as React from 'react';
import cx from 'classnames';

type ColumnsPropsType = {
    children?: React.ReactNode | React.ReactNode[];
    width?: number | string;
    horizontalPadding?: number;
    isMobileOrderInverted?: boolean;
    vAlignTop?: boolean;
    vAlignBottom?: boolean;
    hasMobileLayout?: boolean;
    gap?: number | string;
    equal?: boolean;
};

function Columns(props: ColumnsPropsType) {
    const className = cx({
        columns: true,
        'columns--mobile': props.hasMobileLayout,
        'columns--mobile-inverted': props.isMobileOrderInverted,
        'columns--align-top': props.vAlignTop,
        'columns--align-bottom': props.vAlignBottom,
        'columns--equal': props.equal,
    });

    const itemGap = typeof props.gap === 'number' ? `${props.gap}px` : props.gap;

    return (
        <div
            className={className}
            style={{
                ['--columns-item-gap' as string]: itemGap,
                width: props.width,
                padding: props.horizontalPadding ? `0px ${props.horizontalPadding}px` : undefined,
            }}
        >
            {props.children}
        </div>
    );
}

export default Columns;
