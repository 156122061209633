import Localization from 'lib/localization';

export default {
    // @TODO update after PRD update - stage 2
    get lxSeoPageTitle() {
        return 'Bumble - Date, Meet, Network Better';
    },

    get lxSeoPageDescription() {
        return 'Our mission is to create a world where all relationships are healthy and equitable. ';
    },

    get lxHeroBlockTitle() {
        return Localization.get('bumblesite@team_bumble.page_index.hero_block.title');
    },
    get lxCookieBannerTitle() {
        return Localization.get('bumblesite@team_bumble.page_index.cookie.banner.title');
    },
    lxCookieBannerText(params: { cookiePolicyLink: string }) {
        // copy from MW-11391
        let text = Localization.get('bumblesite@team_bumble.page_index.cookie.banner.text');
        text = text.replace(
            /<a p\d>/,
            `<a style="text-decoration: underline; color: currentColor;" target="_blank" href="${params.cookiePolicyLink}">`
        );
        return text;
    },
    get lxCookieBannerManageOptions() {
        return Localization.get('bumblesite@team_bumble.page_index.cookie.banner.manage_options');
    },
    get lxCookieBannerAcceptAll() {
        return Localization.get('bumblesite@team_bumble.page_index.cookie.banner.accept_all');
    },
    get lxNavigationMenu() {
        return Localization.get('bumblesite@team_bumble.page_index.navigation.menu');
    },
    get lxNavigationOpenRoles() {
        return Localization.get('bumblesite@team_bumble.page_index.navigation.open_roles');
    },
    get lxNavigationLinksAbout() {
        return Localization.get('bumblesite@team_bumble.page_index.navigation.links.about');
    },
    get lxNavigationLinksLife() {
        return Localization.get('bumblesite@team_bumble.page_index.navigation.links.life');
    },
    get lxNavigationLinksTeams() {
        return Localization.get('bumblesite@team_bumble.page_index.navigation.links.teams');
    },
    get lxNavigationLinksBenefits() {
        return Localization.get('bumblesite@team_bumble.page_index.navigation.links.benefits');
    },
    get lxNavigationMenuJoinCta() {
        return Localization.get('bumblesite@team_bumble.page_index.navigation.menu.join_cta');
    },
    get lxFooterBody() {
        return Localization.get('bumblesite@team_bumble.page_index.footer.body');
    },
    get lxFooterLinksSlavery() {
        return Localization.get('bumblesite@team_bumble.page_index.footer.links.slavery');
    },
    get lxFooterLinksGenderGap() {
        return Localization.get('bumblesite@team_bumble.page_index.footer.links.gender_gap');
    },
    get lxFooterLinksTerms() {
        return Localization.get('bumblesite@team_bumble.page_index.footer.links.terms');
    },
    get lxFooterLinksPrivacy() {
        return Localization.get('bumblesite@team_bumble.page_index.footer.links.privacy');
    },
    get lxFooterLinksCookie() {
        return Localization.get('bumblesite@team_bumble.page_index.footer.links.cookie');
    },
    get lxSuccessStoryTitle() {
        return Localization.get('bumblesite@team_bumble.page_index.success_story.title');
    },
    lxSuccessStoryCoupleNames(params: { name1: string; name2: string }) {
        return Localization.get(
            'bumblesite@team_bumble.page_index.success_story.couple.names',
            params
        );
    },
    lxSuccessStoryText(params: { year: string }) {
        return Localization.get(
            'bumblesite@team_bumble.page_index.success_story.couple.names',
            params
        );
    },
    get lxCeoMessageTitle() {
        return Localization.get('bumblesite@team_bumble.page_index.ceo_message.title');
    },
    get lxCeoMessageText() {
        return Localization.get('bumblesite@team_bumble.page_index.ceo_message.text');
    },
    get lxCeoFullName() {
        return Localization.get('bumblesite@team_bumble.ceo.full_name');
    },
};
