import Localization from 'lib/localization';

export default {
    get lxFooterLinksTerms() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.TNC');
    },
    get lxFooterLinksPrivacy() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.PRIVACY_POLICY');
    },
    get lxFooterLinksEvents() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.EVENTS');
    },
    get lxFooterLinksFAQ() {
        return Localization.get('bumble_mobile_web@company.link.faq');
    },
    get lxFooterLinksSlaveryAct() {
        return Localization.get('badoo_homepage@footer.slavery_act');
    },
    get lxFooterLinksCareers() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.CAREERS');
    },
    get lxFooterLinksContacts() {
        return Localization.get('bma_client@pages.faq.section.items.contact_us');
    },
    get lxFooterLinksGuides() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.SEASONAL_DATING_GUIDES');
    },
    get lxFooterLinksGuidelines() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.GUIDELINES');
    },
    get lxFooterLinksCookie() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES');
    },
    get lxFooterLinksSeasonalDatingGuides() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.SEASONAL_DATING_GUIDES');
    },
    get lxFooterLinksFriendshipHub() {
        return Localization.get('bumblesite@LAYOUT.FOOTER.SEASONAL_DATING_GUIDES');
    },
    get lxSuccessStoryTitle() {
        return Localization.get('bumblesite@team_bumble.page_index.success_story.title');
    },
};
