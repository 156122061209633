import { useState, useEffect } from 'react';

type DeviceType = 'pc' | 'tablet' | 'mobile' | 'bot';
type OS = 'ios' | 'android' | 'other' | 'bot';
type Browser = 'chrome' | 'firefox' | 'safari' | 'edge' | 'ie' | 'other' | 'bot';

interface DeviceInfo {
    deviceType: DeviceType;
    os: OS;
    browser: Browser;
}

export const useDeviceInfo = (): DeviceInfo => {
    const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
        deviceType: 'pc',
        os: 'other',
        browser: 'other',
    });

    useEffect(() => {
        const userAgent = navigator.userAgent;
        // @ts-ignore
        const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
        const isAndroid = /Android/.test(userAgent);

        if (isIOS) {
            setDeviceInfo({
                deviceType: 'mobile',
                os: 'ios',
                browser: 'other',
            });
        } else if (isAndroid) {
            setDeviceInfo({
                deviceType: 'mobile',
                os: 'android',
                browser: 'other',
            });
        } else {
            setDeviceInfo({
                deviceType: 'pc',
                os: 'other',
                browser: 'other',
            });
        }
    }, []);

    return deviceInfo;
};
