import { baseUrl } from 'lib/base-url';
import { basePathIndexUrl } from 'lib/schemes';

export function updateImagePaths(imagePath: string) {
    return imagePath.replace('http://microsite-cms-ceph.mlan:7480', 'http://cdn-internal:8081');
}

export function updateImagePathsRegExp(response: string) {
    return response.replace(
        // // @TODO add more dynamic solution for url change
        /http:\\\/\\\/microsite-cms-ceph\.mlan:7480\\\//g,
        'http://cdn-internal:8081/'
    );
}

export function updateImagePathsRegExpFromBlocks(response: string) {
    return response.replace(
        // // @TODO add more dynamic solution for url change
        /http:\\\/\\\/microsite-cms-ceph\.mlan:7480\\\//g,
        'http://cdn-internal:8081'
    );
}

// Converts an image source to a dynamic next image if it's an internal cdn url
export function convertToNextImage(src: string, useBaseUrl = true) {
    if (src.startsWith('http://cdn-internal:8081/')) {
        const absUrl = src
            .replace(
                'http://cdn-internal:8081/bumble-bff-landing-production/sites/5/',
                `${basePathIndexUrl}/cdn-content-hub/`
            )
            .replace(
                'http://cdn-internal:8081/bumble-bff-landing-staging/sites/5/',
                `${basePathIndexUrl}/cdn-content-hub/`
            );

        return useBaseUrl ? `${baseUrl}${absUrl}` : absUrl;
    }

    return src;
}

export function setAssetPathWithLang(lang: string, imageSrc: string) {
    return `${basePathIndexUrl}/assets/${lang.toLocaleLowerCase()}/${imageSrc}`;
}
