export enum SocialNetwork {
    None = 'None', // Fallback for VSCODE autocomplete Bug with Zero index Enum
    // Facebook = 'Facebook',
    Instagram = 'Instagram',
    LinkedIn = 'LinkedIn',
    Pinterest = 'Pinterest',
    TikTok = 'TikTok',
    Twitter = 'Twitter',
    YouTube = 'YouTube',
}

export enum SocialNetworkProfileBumble {
    None = '',
    // Facebook = 'https://www.facebook.com/bumbleapp',
    Instagram = 'https://www.instagram.com/bumble',
    LinkedIn = 'https://www.linkedin.com/company/bumble',
    Pinterest = 'https://www.pinterest.com/bumbleapp/',
    TikTok = 'https://www.tiktok.com/@bumble',
    Twitter = 'https://twitter.com/bumble',
    YouTube = 'https://www.youtube.com/channel/UCERo8J7mug7cVcwIKaoJLww',
}

export enum SocialNetworkProfileBumbleBFF {
    None = '',
    // Facebook = 'https://www.facebook.com/BumbleBFF',
    Instagram = 'https://www.instagram.com/bumbleforfriends',
}

/**
 * Social networks to show on the actual website
 */
export const SocialNetworkProfileLinks: {
    [key in SocialNetwork]: SocialNetworkProfileBumble | SocialNetworkProfileBumbleBFF;
} = {
    [SocialNetwork.None]: SocialNetworkProfileBumble.None,
    // [SocialNetwork.Facebook]: SocialNetworkProfileBumbleBFF.Facebook,
    [SocialNetwork.Instagram]: SocialNetworkProfileBumbleBFF.Instagram,
    [SocialNetwork.LinkedIn]: SocialNetworkProfileBumble.LinkedIn,
    [SocialNetwork.Pinterest]: SocialNetworkProfileBumble.Pinterest,
    [SocialNetwork.TikTok]: SocialNetworkProfileBumble.TikTok,
    [SocialNetwork.Twitter]: SocialNetworkProfileBumble.Twitter,
    [SocialNetwork.YouTube]: SocialNetworkProfileBumble.YouTube,
};

export const socialNetworkLinks = {
    [SocialNetwork.None]: SocialNetworkProfileLinks[SocialNetwork.None],
    // [SocialNetwork.Facebook]: SocialNetworkProfileLinks[SocialNetwork.Facebook],
    [SocialNetwork.Instagram]: SocialNetworkProfileLinks[SocialNetwork.Instagram],
    [SocialNetwork.LinkedIn]: SocialNetworkProfileLinks[SocialNetwork.LinkedIn],
    [SocialNetwork.Pinterest]: SocialNetworkProfileLinks[SocialNetwork.Pinterest],
    [SocialNetwork.TikTok]: SocialNetworkProfileLinks[SocialNetwork.TikTok],
    [SocialNetwork.Twitter]: SocialNetworkProfileLinks[SocialNetwork.Twitter],
    [SocialNetwork.YouTube]: SocialNetworkProfileLinks[SocialNetwork.YouTube],
};
