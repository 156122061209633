import * as React from 'react';
import { PageSEOPropsType } from 'components/page-seo/page-seo';
import {
    Maybe,
    Post,
    RootQueryToCategoryConnection,
    Scalars,
} from 'interfaces/bumble-bff-landing-microsite-cms';
import PageSection from 'components/page/page-section';
import { Title, SubTitle, Header2, P1 } from 'components/typography';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';
import { PageCategoriesList } from 'components/more-category-posts/more-category-posts';
import HeroImage from 'components/hero-image/hero-image';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import { Columns, ColumnsItem, ColumnsSize } from 'components/columns';
import { TextTagName } from 'components/typography/text';
import Button from 'components/button/button';
import { clickEventFromElement } from 'components/google-analytics/google-analytics';
import PageTemplateContentSvgContainer from './page-template-svg-container';
import { InView } from 'react-intersection-observer';
import { SocialSharingBlock } from 'components/social-sharing-block/social-sharing-block';
import { FeedGrid } from 'components/feed-grid/feed-grid';
import { useAppMarketUrl } from 'lib/set-app-market-url';
import FormEmailCapture from 'components/form/form-email-capture';
import lexemes from 'components/lexemes/bff/lexemes';
import { basePathIndexUrl } from 'lib/schemes';
import { setAssetPathWithLang } from 'lib/updateImagePaths';
import { useDeviceInfo } from 'lib/use-device-info';
import { AppstoreLinks } from 'components/appstore-links/appstore-links';
import IframeSection from 'components/Blocks/IframeSection/IframeSection';

export enum PageTemplateContentType {
    Index = 'Index',
    Archive = 'Archive',
    Article = 'Article',
}

type renderPageElementProps = {
    condition: boolean;
    children?: React.ReactNode;
};

export function renderPageElement(props: renderPageElementProps) {
    return props.condition ? props.children : null;
}

type PageTemplateContentProps = {
    id?: Scalars['Int'];
    lang: SupportedLanguage;
    type: PageTemplateContentType;
    intro?: React.ReactNode;
    categories?: Maybe<RootQueryToCategoryConnection>;
    pageCategoriesList?: PageCategoriesList;
    children?: React.ReactNode;
    modal?: React.ReactNode;
    title?: string;
    browserTab?: string;
    seoTitle?: string;
    seoDescription?: string;
    seoUrl?: string;
    openTitle?: string;
    openDescription?: string;
    datePublished?: string;
    dateModified?: string;
    // @TODO: add support in CMS
    metaKeywords?: string | null;
    metaRobotsNoindex?: string | null;
    metaRobotsNofollow?: string | null;
    opengraphPublishedTime?: string | null;
    opengraphModifiedTime?: string | null;
    openImageUrl?: string | null;
    openImageAlt?: string | null;
    heroImageUrl?: string | null;
    heroImageAlt?: string | null;
    heroImageHeight?: string | null;
    heroImageWidth?: string | null;
    openSiteName?: string;
    hasFooterCta?: boolean;
    footerTitleText?: Maybe<string> | undefined;
    hasFooterCtaButton?: boolean;
    ctaButtonText?: Maybe<string> | undefined;
    ctaButtonUrl?: Maybe<string> | undefined;
    ctaButtonTargetNewWindow?: boolean;
    onPageClick?: () => void;
    dynamicFooter?: boolean;
    geoIpCountry?: string | null;
    pageUrl?: string;
    articleUrl?: string;
    posts?: Maybe<Maybe<Post>[]> | undefined;
} & PageSEOPropsType;

function PageTemplateContent(props: PageTemplateContentProps) {
    const isBreakpoint = useMediaQuery(MediaQueryWidth['tablet-md']);
    const pageCta = useAppMarketUrl({ geoIpCountry: props.geoIpCountry });
    const title = isBreakpoint
        ? lexemes.lxbffLandingSectionOneHeaderMobile
        : lexemes.lxbffLandingSectionOneHeaderDesktop;

    const { deviceType } = useDeviceInfo();

    return (
        <>
            <PageSection inner={false} extraClass="page-section--bff-mode">
                <PageTemplateContentSvgContainer />
                <Columns
                    vAlignTop={true}
                    hasMobileLayout={true}
                    isMobileOrderInverted={true}
                    gap={16}
                >
                    <ColumnsItem size={ColumnsSize['1/1']} extraClass="columns__item--slider">
                        <div className="slider-svg">
                            <div className="slider-svg__inner-wrapper">
                                <svg
                                    className="slider-svg__inner"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 577 705"
                                    aria-hidden="true"
                                >
                                    <image
                                        className="slider-svg__slide"
                                        xlinkHref="/bff/assets/hero-banner-image-4.jpg"
                                    />
                                    <image
                                        className="slider-svg__slide"
                                        xlinkHref="/bff/assets/hero-banner-image-3.jpg"
                                    />
                                    <image
                                        className="slider-svg__slide"
                                        xlinkHref="/bff/assets/hero-banner-image-2.jpg"
                                    />
                                    <image
                                        className="slider-svg__slide"
                                        xlinkHref="/bff/assets/hero-banner-image-1.jpg"
                                    />
                                </svg>
                            </div>
                            <div className="slider-svg__sticker slider-svg__sticker--inner-text">
                                <svg
                                    className="slider-svg__sticker-back"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 175 177"
                                    aria-hidden="true"
                                >
                                    <use xlinkHref="#slider-sticker-logo-back"></use>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 260 260"
                                    style={{
                                        animation: 'rotating 20s linear infinite',
                                        transformOrigin: 'center',
                                    }}
                                    aria-hidden="true"
                                >
                                    <use xlinkHref="#slider-sticker-logo-text"></use>
                                </svg>
                            </div>

                            <div className="slider-svg__sticker slider-svg__sticker--friends-interests">
                                <svg
                                    className="slider-svg__sticker-back"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 345 251"
                                    aria-hidden="true"
                                >
                                    <use xlinkHref="#slider-sticker-friends-sticker"></use>
                                </svg>
                            </div>
                            <div className="slider-svg__sticker slider-svg__sticker--plant-parent">
                                <HeroImage
                                    // @TODO: add lexeme
                                    alt={lexemes.lxbffLandingStickerOneLabelAlt}
                                    aspectRatio={159 / 599}
                                    objectFit="contain"
                                    src={setAssetPathWithLang(props.lang, 'badplantparent.png')}
                                />
                            </div>
                        </div>
                    </ColumnsItem>
                    <ColumnsItem size={ColumnsSize['1/1']} extraClass="columns__item--title">
                        <div className="page-section__title">
                            <Title html={title} tagname={TextTagName.h1} />
                        </div>
                        <div className="page-section__subtitle">
                            <SubTitle
                                html={lexemes.lxbffLandingSectionOneSubHeader}
                                tagname={TextTagName.h2}
                            />
                        </div>
                        <div className="page-section__cta">
                            {deviceType === 'pc' ? (
                                <AppstoreLinks language={props.lang} />
                            ) : (
                                <Button
                                    color={Button.color.Dark}
                                    size={Button.size.MEDIUM}
                                    text={lexemes.lxbffLandingSectionOneCta}
                                    href={pageCta}
                                    target="_blank"
                                    seo={{
                                        category: 'Navigation',
                                        action: 'Button Click',
                                        label: lexemes.lxbffLandingSectionOneCta,
                                    }}
                                    onClick={(event) => clickEventFromElement(event)}
                                    extraClass="button--title-cta"
                                />
                            )}
                        </div>
                    </ColumnsItem>
                </Columns>
            </PageSection>
            <PageSection inner={true}>
                <Columns hasMobileLayout={true} isMobileOrderInverted={true} gap={16}>
                    <ColumnsItem size={ColumnsSize['1/1']} extraClass="columns__item--hero-image">
                        <HeroImage
                            aspectRatio={163 / 127}
                            objectFit="contain"
                            alt={lexemes.lxbffLandingSectionTwoHeader}
                            src={setAssetPathWithLang(props.lang, 'introblock.png')}
                            hasAspect={true}
                            priority={false}
                        />
                    </ColumnsItem>
                    <ColumnsItem size={ColumnsSize['1/1']} extraClass="columns__item--right-gap">
                        <Header2
                            html={lexemes.lxbffLandingSectionTwoHeader}
                            tagname={TextTagName.h2}
                        ></Header2>
                        <div style={{ marginTop: isBreakpoint ? 24 : 16 }}>
                            <P1 html={lexemes.lxbffLandingSectionTwoText} />
                        </div>
                    </ColumnsItem>
                </Columns>
            </PageSection>
            <PageSection inner={true}>
                <Columns vAlignTop={true} hasMobileLayout={true} gap={isBreakpoint ? 48 : 16}>
                    <ColumnsItem size={ColumnsSize['1/1']} extraClass="column__item--tablet-cta">
                        <HeroImage
                            aspectRatio={1}
                            objectFit="contain"
                            alt={lexemes.lxbffLandingSectionThreeColumnLeftHeader}
                            src={setAssetPathWithLang(props.lang, 'join.jpg')}
                            priority={false}
                        />
                        {/* <div
                            className="column__item-title"
                            style={{ marginTop: isBreakpoint ? 24 : 16 }}
                        >
                            <Header3
                                tagname={TextTagName.h3}
                                html={lexemes.lxbffLandingSectionThreeColumnLeftHeader}
                            />
                        </div> */}
                        <div
                            className="column__item-cta"
                            style={{ marginTop: isBreakpoint ? 24 : 0 }}
                        >
                            <Button
                                size={Button.size.MEDIUM}
                                text={lexemes.lxbffLandingSectionThreeColumnLeftCta}
                                href={pageCta}
                                target="_blank"
                                seo={{
                                    category: 'Navigation',
                                    action: 'Button Click',
                                    label: lexemes.lxbffLandingSectionThreeColumnLeftCta,
                                }}
                                onClick={(event) => clickEventFromElement(event)}
                                extraClass="button--cta-read-more"
                            />
                        </div>
                    </ColumnsItem>
                    <ColumnsItem size={ColumnsSize['1/1']} extraClass="column__item--tablet-cta">
                        <HeroImage
                            aspectRatio={1}
                            objectFit="contain"
                            alt={lexemes.lxbffLandingSectionThreeColumnRightTitle}
                            src={setAssetPathWithLang(props.lang, 'interests.jpg')}
                            priority={false}
                        />
                    </ColumnsItem>
                </Columns>
            </PageSection>
            <PageSection inner={true} extraClass="page-section--bff-form">
                <FormEmailCapture lang={props.lang} />
            </PageSection>
            <PageSection inner={true}>
                <InView>
                    {({ inView, ref }) => (
                        <div ref={ref}>
                            <HeroImage
                                aspectRatio={isBreakpoint ? 0.562248 : 1.78}
                                objectFit="contain"
                                alt={lexemes.lxbffLandingSectionFiveColumnTitle}
                                src={
                                    isBreakpoint
                                        ? `${basePathIndexUrl}/assets/social-block-mobile.jpg`
                                        : setAssetPathWithLang(props.lang, 'socialblock.jpg')
                                }
                                priority={false}
                            />
                            <div className="column__item-cta-bottom">
                                <Header2
                                    tagname={TextTagName.h3}
                                    html={lexemes.lxbffLandingSectionFiveColumnTitle}
                                />
                            </div>
                            {inView ? (
                                <video
                                    className="page-section__video"
                                    autoPlay={true}
                                    loop={true}
                                    muted={true}
                                    playsInline={true}
                                >
                                    <source
                                        src="https://bumble.com/bumble-bff-video-find-your-people"
                                        type="video/mp4"
                                    />
                                </video>
                            ) : null}
                        </div>
                    )}
                </InView>
                <div
                    className={`page-section__social-sharing-block  ${
                        isBreakpoint ? 'is-tilted' : 'has-shadow'
                    }`}
                >
                    <SocialSharingBlock title={lexemes.lxbffLandingSocialMediaTitle} />
                </div>
            </PageSection>
            <PageSection inner={false} extraClass="page-section--no-bg">
                <InView>
                    {({ inView, ref }) => (
                        <div ref={ref}>
                            {inView ? (
                                <>
                                    {/* @ts-ignore */}
                                    <IframeSection widgetId="TYS6TH41" hasPadding={false} />
                                </>
                            ) : null}
                        </div>
                    )}
                </InView>
            </PageSection>
            <FeedGrid title={lexemes.lxbffLandingSectionFiveColumnRightTitle} posts={props.posts} />
            {props.children}
        </>
    );
}
PageTemplateContent.type = PageTemplateContentType;

export default PageTemplateContent;
