/**
 * more details - next.config.js -basePath
 */
export const basePathIndexUrl = '/bff';
const categoryNamesList = JSON.stringify(['Fall', 'Winter', 'Spring', 'Summer']);

export const getCategoryPageByUrlScheme = `
fragment InnerBlocksFields on ContentBlock {
  name
  attributes {
    name
    value
  }
  innerHTML(removeWrappingTag: true)
}

fragment LanguagePageProps on Page {
  language {
    locale
  }
}

fragment ContentBlocksPageProps on Page {
  contentBlocks {
    blocks {
      ...InnerBlocksFields
      innerBlocks {
        ...InnerBlocksFields
        innerBlocks {
          ...InnerBlocksFields
          innerBlocks {
            ...InnerBlocksFields
            innerBlocks {
              ...InnerBlocksFields
            }
          }
        }
      }
    }
  }
}

fragment PageProps on Page {
  seo {
    title
    metaDesc
    metaKeywords
    metaRobotsNoindex
    metaRobotsNofollow
    opengraphPublishedTime
    opengraphModifiedTime
  }
  title
  featuredImage {
    node {
      altText
      mediaDetails {
        height
        width
      }
      mediaItemUrl
      mimeType
      srcSet
      sourceUrl
    }
  }
  ...LanguagePageProps
  ...ContentBlocksPageProps
}

query getCategoryPageByUrl($url: String!, $categoryNamesList: [String]) {
  nodeByUri(uri: $url) {
    ... on Page {
      ...PageProps
      translations {
        ...PageProps
      }
    }
  }
  categories(where: {name: $categoryNamesList, hideEmpty: true}) {
    edges {
        node {
          name
          slug
          postCategoryFieldGroup {
            order
            backgroundcolor
            ctaText
            ctaUrl {
              url
              target
              title
            }
            showonmainpage
          }
          posts(first: 8) {
            edges {
              node {
                ... on Post {
                  title
                  uri
                  excerpt
                  featuredImage {
                    node {
                      altText
                      mediaItemUrl
                      mimeType
                      srcSet
                      sourceUrl
                    }
                  }
                  postFieldGroup {
                    ctaText
                    ctaUrl {
                      url
                      target
                      title
                    }
                  }
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
  }
}
`;

export const getIndexPageByUrlScheme = `
fragment InnerBlocksFields on ContentBlock {
  name
  attributes {
    name
    value
  }
  innerHTML(removeWrappingTag: true)
}

fragment LanguagePageProps on Page {
  language {
    locale
  }
}

fragment ContentBlocksPageProps on Page {
  contentBlocks {
    blocks {
      ...InnerBlocksFields
      innerBlocks {
        ...InnerBlocksFields
        innerBlocks {
          ...InnerBlocksFields
          innerBlocks {
            ...InnerBlocksFields
            innerBlocks {
              ...InnerBlocksFields
            }
          }
        }
      }
    }
  }
}

fragment PageProps on Page {
  seo {
    title
    metaDesc
    metaKeywords
    metaRobotsNoindex
    metaRobotsNofollow
    opengraphPublishedTime
    opengraphModifiedTime
  }
  title
  featuredImage {
    node {
      altText
      mediaDetails {
        height
        width
      }
      mediaItemUrl
      mimeType
      srcSet
      sourceUrl
    }
  }
  ...LanguagePageProps
  ...ContentBlocksPageProps
}

query getIndexPageByUrl($uri: String! = "${basePathIndexUrl}", $categoryNamesList: [String] = ${categoryNamesList}) {
  nodeByUri(uri: $uri) {
    ... on Page {
      ...PageProps
    }
  }
  categories(where: {name: $categoryNamesList, hideEmpty: true}) {
    edges {
      node {
        name
        slug
        postCategoryFieldGroup {
          order
          backgroundcolor
          ctaText
          ctaUrl {
            url
            target
            title
          }
          showonmainpage
        }
        posts(first: 4) {
          edges {
            node {
              ... on Post {
                databaseId
                title
                uri
                excerpt
                featuredImage {
                  node {
                    altText
                    mediaItemUrl
                    mimeType
                    srcSet
                    sourceUrl
                  }
                }
                postFieldGroup {
                  ctaText
                  ctaUrl {
                    url
                    target
                    title
                  }
                }
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
}
`;

/**
 * uri example: '/bff/cuffing-season'
 * note about more articles: some categories have only 4 articles
 */
export const getArticlePageByUrlScheme = `
fragment InnerBlocksFields on ContentBlock {
    name
    attributes {
      name
      value
    }
    innerHTML(removeWrappingTag: true)
  }
  
  fragment ContentBlocksPostProps on Post {
    contentBlocks {
      blocks {
        ...InnerBlocksFields
        innerBlocks {
          ...InnerBlocksFields
          innerBlocks {
            ...InnerBlocksFields
            innerBlocks {
              ...InnerBlocksFields
              innerBlocks {
                ...InnerBlocksFields
              }
            }
          }
        }
      }
    }
  }
  
  fragment PostProps on Post {
    title
    ...ContentBlocksPostProps
    databaseId
    excerpt
    categories {
      edges {
        node {
            name
            slug
            postCategoryFieldGroup {
              order
              backgroundcolor
              ctaText
              ctaUrl {
                url
                target
                title
              }
              showonmainpage
            }
            posts(first: 5) {
              edges {
                node {
                  ... on Post {
                    databaseId
                    title
                    uri
                    excerpt
                    featuredImage {
                      node {
                        altText
                        mediaItemUrl
                        mimeType
                        srcSet
                        sourceUrl
                      }
                    }
                    postFieldGroup {
                      ctaText
                      ctaUrl {
                        url
                        target
                        title
                      }
                    }
                  }
                }
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
          }
      }
    }
    uri
    featuredImage {
      node {
        altText
        mediaDetails {
          height
          width
        }
        mediaItemUrl
        mimeType
        srcSet
        sourceUrl
      }
    }
    seo {
      title
      metaDesc
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphPublishedTime
      opengraphModifiedTime
    }
    postFieldGroup {
      ctaText
      ctaUrl {
        url
        target
        title
      }
      footerCtaText
      footerCtaUrl {
        url
        target
        title
      }
    }
  }
  query getArticlePageByUrl($uri: String!) {
    nodeByUri(uri: $uri) {
      ... on Post {
        ...PostProps
      }
    }
  }
`;

export const getSiteMapScheme = `
query GET_SITEMAP {
  pages(where: {language: DEFAULT}) {
    nodes {
      slug
      modified
      contentBlocks {
        blocks {
          name
        }
      }
    }
  }
  posts(first: 999, where: {language: DEFAULT}) {
    nodes {
      slug
      modified
      contentBlocks {
        blocks {
          name
        }
      }
    }
  }
}
`;

export const getPagePreviewById = `
fragment InnerBlocksFields on ContentBlock {
  name
  attributes {
    name
    value
  }
  innerHTML(removeWrappingTag: true)
}

fragment LanguagePageProps on Page {
  language {
    locale
  }
}

fragment ContentBlocksPageProps on Page {
  contentBlocks {
    blocks {
      ...InnerBlocksFields
      innerBlocks {
        ...InnerBlocksFields
        innerBlocks {
          ...InnerBlocksFields
          innerBlocks {
            ...InnerBlocksFields
            innerBlocks {
              ...InnerBlocksFields
            }
          }
        }
      }
    }
  }
}

fragment PageProps on Page {
  seo {
    title
    metaDesc
    metaKeywords
    metaRobotsNoindex
    metaRobotsNofollow
    opengraphPublishedTime
    opengraphModifiedTime
  }
  title
  featuredImage {
    node {
      altText
      mediaDetails {
        height
        width
      }
      mediaItemUrl
      mimeType
      srcSet
      sourceUrl
    }
  }
  ...LanguagePageProps
  ...ContentBlocksPageProps
}

query getPagePreviewById($id: ID!, $asPreview: Boolean = false) {
  page(id: $id, idType: DATABASE_ID, asPreview: $asPreview) {
    ... on Page {
      ...PageProps
      translations {
        ...PageProps
      }
    }
  }
}
`;

export const getRecentCategoryPostsScheme = `
query RecentCategoryPosts($categoryName: [String], $first: Int, $after: String, $tagSlugNotIn: [ID]) {
    categories(where: {name: $categoryName}) {
      edges {
        node {
          posts(
            first: $first
            after: $after
            where: {orderby: {field: DATE, order: DESC}, notIn: $tagSlugNotIn}
          ) {
            edges {
              node {
                ... on Post {
                  databaseId
                  title
                  uri
                  excerpt
                  featuredImage {
                    node {
                      altText
                      mediaItemUrl
                      mimeType
                      srcSet
                      sourceUrl
                    }
                  }
                  postFieldGroup {
                    ctaText
                    ctaUrl {
                      url
                      target
                      title
                    }
                  }
                }
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
    }
  }
`;

export const getFriendshipCategoryPosts = `
query FriendshipPosts($first: Int) {
    category(id: "Uncategorized", idType: NAME) {
      posts(first: $first) {
        nodes {
          title
          uri
          date
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;
