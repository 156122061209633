import Localization from 'lib/localization';

export const redirectToMainSite = {
    redirect: {
        destination: 'https://bumble.com/?from=bumble-bff-landing-page-not-found',
        permanent: true,
    },
};

export const bumbleBlock = {
    page_partner_name: 'Bumble',
};
export const badooBlock = {
    page_partner_name: 'Badoo',
    page_badoo_name: 'Badoo',
};
export const bothBrandsBlock = {
    page_partner_name: 'Bumble',
    page_badoo_name: 'Badoo',
};

export const routes = {
    group_site: 'https://bumble.com/',
    index: '/',
    privacy: '/privacy',
    candidate_cookie_policy: '/candidate-privacy-policy',
    terms: '/terms',
    cookie_policy: '/cookie-policy',
    // exception due to id in href.
    // @todo - refactor link component to fix this case in future
    cookie_policy_functional_anchor: 'https://bumble.com/cookie-policy#functional',
    gender_pay: 'https://gender-pay-gap.service.gov.uk/Employer/MADP5z7b/2020',
    about: '/about',
    bumble_about: '/about',
    badoo_about: 'https://badoo.com/about',
    life: '/life',
    teams: '/teams',
    teams_engineering: '/teams/engineering',
    teams_marketing: '/teams/marketing',
    teams_creative_design: '/teams/creative-and-design',
    benefits: '/benefits',
    benefits_austin: '/benefits/austin',
    benefits_london: '/benefits/london',
    benefits_moscow: '/benefits/moscow',
    benefits_barcelona: '/benefits/barcelona',
    open_roles: '/open-roles',
};

export const external_routes = {
    medium_bumble_tech: 'https://medium.com/bumble-tech',
};

// @todo - add lexemes
export const footerMenuItemsTeamBumble = {
    left: [
        {
            titleList: [
                {
                    get title() {
                        return Localization.get(
                            'bumblesite@team_bumble.page_index.footer.links.slavery'
                        );
                    },
                    url: 'https://bumbcdn.com/big/documents/bumble/statement-on-modern-slavery-act.pdf',
                    isExternal: true,
                },
                {
                    get title() {
                        // @todo - update lexeme
                        return 'Candidate Privacy Policy';
                        // return Localization.get(
                        // 'bumblesite@team_bumble.page_index.footer.links.privacy'
                        // );
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}${routes.candidate_cookie_policy}`;
                    },
                    isExternal: true,
                },
                {
                    get title() {
                        return Localization.get(
                            'bumblesite@team_bumble.page_index.footer.links.gender_gap'
                        );
                    },
                    url: routes.gender_pay,
                    isExternal: true,
                },
            ],
        },
        {
            get title() {
                return Localization.get('bumblesite@team_bumble.page_index.footer.links.terms');
            },
            getUrl(lang: string) {
                return `https://bumble.com/${lang}${routes.terms}`;
            },
            isExternal: true,
        },
        {
            get title() {
                return Localization.get('bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES');
            },
            getUrl() {
                return `/#manage-cookies`;
            },
            className: 'js-manage-cookies',
        },
    ],
    leftSecondRow: [
        {
            titleList: [
                {
                    get title() {
                        return Localization.get(
                            'bumblesite@team_bumble.page_index.footer.links.gender_gap'
                        );
                    },
                    url: routes.gender_pay,
                    isExternal: true,
                },
            ],
        },
        {
            titleList: [
                {
                    get title() {
                        return Localization.get(
                            'bumblesite@team_bumble.page_index.footer.links.slavery'
                        );
                    },
                    url: 'https://bumbcdn.com/big/documents/bumble/statement-on-modern-slavery-act.pdf',
                    isExternal: true,
                },
            ],
        },
    ],
    right: [
        {
            get title() {
                return Localization.get('bumblesite@team_bumble.page_index.navigation.links.about');
            },
            url: routes.about,
        },
        {
            get title() {
                return Localization.get('bumblesite@team_bumble.page_index.navigation.links.life');
            },
            url: routes.life,
        },
        {
            get title() {
                // @todo add lexeme, when it will be provided
                return 'Teams';
            },
            url: routes.teams,
        },
        {
            get title() {
                // @todo add lexeme, when it will be provided
                return 'Benefits';
            },
            url: routes.benefits,
        },
    ],
};
