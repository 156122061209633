import React from 'react';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import Icon from 'components/icon/icon';
import Button from 'components/button/button';
import Loader from 'components/loader/loader';
import FormField from 'components/form/form-field';
import { Columns, ColumnsItem, ColumnsSize } from 'components/columns';
import { Header1, Header2, P1 } from 'components/typography';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import lexemes from 'components/lexemes/bff/lexemes';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';

const API_CAPTCHA_URL = process.env.NEXT_PUBLIC_EMAIL_CAPTHA_URL;
const API_EMAIL_URL = process.env.NEXT_PUBLIC_EMAIL_API_URL;

function getImageCaptcha() {
    /**
     * currently an issue with domain availability due to no internet access
     */
    return `${API_CAPTCHA_URL}?reload=1&rand=${Date.now().toString().slice(-5)}`;
}

type ResponseErrorData = {
    code?: string;
    message?: string;
};

async function readDataFromResponse(response: Response): Promise<ResponseErrorData | undefined> {
    const reader = response.body?.getReader();
    const decoder = new TextDecoder('utf-8');
    const chunks: Uint8Array[] = [];

    /**
     * success case
     */
    if (response.status === 200) {
        return;
    }

    while (true) {
        if (!reader) {
            return;
        }

        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await reader.read();
        if (done) {
            break;
        }
        chunks.push(value as Uint8Array);
    }

    /**
     * error cases from server
     */
    return JSON.parse(decoder.decode(new Uint8Array(chunks[0])));
}

enum FormError {
    CAPTCHA = 'Invalid symbols.',
}

enum FormState {
    IDLE = 'IDLE',
    LOADING = 'LOADING',
    INVALID = 'INVALID',
    SUCCESS = 'SUCCESS',
}

const initialFormState = FormState.IDLE;

interface FormData {
    code: string;
    email: string;
    firstName: string;
    lastName: string;
}

const initialFormData = {
    code: '',
    email: '',
    firstName: '',
    lastName: '',
};

type FormEmailCaptureProps = {
    lang: SupportedLanguage;
};

function FormEmailCapture({ lang }: FormEmailCaptureProps) {
    const [formState, setFormState] = React.useState<FormState>(initialFormState);
    const [formData, setFormData] = React.useState<FormData>(initialFormData);
    const [imageCaptcha, setImageCaptcha] = React.useState<string>(() => getImageCaptcha());
    const isBreakpoint = useMediaQuery(MediaQueryWidth['tablet-md']);
    const isBreakpointSM = useMediaQuery(MediaQueryWidth['tablet-sm']);

    const classnames = {
        block: classNames({
            form: true,
            'form--third': !isBreakpointSM,
            'is-loading': formState === FormState.LOADING,
        }),
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setFormState(FormState.LOADING);

        try {
            const response = await fetch(
                API_EMAIL_URL ? API_EMAIL_URL.replace('/comms', `/${lang}/comms`) : API_EMAIL_URL!,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formData),
                }
            );
            const data = await readDataFromResponse(response);

            if (data && data.code === FormError.CAPTCHA) {
                setImageCaptcha(() => getImageCaptcha());
                return setFormState(FormState.INVALID);
            }

            setFormState(FormState.SUCCESS);
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const textSection = (
        <>
            <Header2 extraClass="form__email-capture-title">
                {lexemes.lxbffLandingEmailCaptureTitle}
            </Header2>
            <div style={{ marginTop: 16 }}>
                <P1 extraClass="form__email-capture-subtitle">
                    {lexemes.lxbffLandingEmailCaptureText}
                </P1>
            </div>
            <div style={{ marginTop: 16 }}>
                <P1 extraClass="form__email-capture-legal-caption">
                    <small
                        dangerouslySetInnerHTML={{
                            __html: lexemes.lxbffLandingEmailCaptureFooter({ lang }),
                        }}
                    />
                </P1>
            </div>
        </>
    );
    const formSection = (
        <form onSubmit={handleSubmit}>
            <div className={classnames.block}>
                <div className="form__content">
                    <div className="form__row">
                        <div className="form__col">
                            <div className="form__field">
                                <FormField
                                    onChange={handleChange}
                                    required={true}
                                    error="error"
                                    placeholder={lexemes.lxbffLandingEmailCaptureFieldFirstName}
                                    value={formData.firstName}
                                    name="firstName"
                                />
                            </div>
                        </div>
                        <div className="form__col">
                            <div className="form__field">
                                <FormField
                                    onChange={handleChange}
                                    required={true}
                                    error="error"
                                    placeholder={lexemes.lxbffLandingEmailCaptureFieldLastName}
                                    value={formData.lastName}
                                    name="lastName"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form__row">
                        <div className="form__col">
                            <div className="form__field">
                                <FormField
                                    type="email"
                                    onChange={handleChange}
                                    required={true}
                                    error="error"
                                    placeholder={
                                        lexemes.lxbffLandingEmailCaptureFieldEmailErrorLabel
                                    }
                                    value={formData.email}
                                    name="email"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form__row">
                        <div className="form__col">
                            <div className="form-field">
                                <div className="form-field__input">
                                    <div className="captcha">
                                        <div className="captcha__media">
                                            <div className="captcha__code">
                                                <Image
                                                    src={imageCaptcha}
                                                    unoptimized={true}
                                                    className="captcha__code-image js-captcha-img"
                                                    width="108"
                                                    height="27"
                                                    alt="code"
                                                />
                                            </div>
                                            <div
                                                className="captcha__reset"
                                                onClick={() =>
                                                    setImageCaptcha(() => getImageCaptcha())
                                                }
                                            >
                                                <div className="captcha__reset-icon">
                                                    <Icon size="md" name={Icon.Name.Refresh} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form__col">
                            <div className="captcha">
                                <div className="captcha__field">
                                    <div className="captcha__input">
                                        <FormField
                                            onChange={handleChange}
                                            required={true}
                                            error={lexemes.lxbffLandingEmailCaptureFieldCaptcha}
                                            placeholder={lexemes.lxbffLandingEmailCaptureFieldEmail}
                                            value={formData.code}
                                            name="code"
                                            hasError={formState === FormState.INVALID}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form__row form__row--gap">
                        <div className="form__col form__col--align-bottom">
                            <Button
                                color={Button.color.Primary}
                                type="submit"
                                text={lexemes.lxbffLandingEmailCaptureFieldCta}
                                extraClass="button--bff-primary"
                                isDisabled={formState === FormState.LOADING}
                            />
                        </div>
                    </div>
                </div>

                {formState === FormState.LOADING ? (
                    <div className="form__loader">
                        <Loader />
                    </div>
                ) : null}
            </div>
        </form>
    );

    const formLayout = !isBreakpoint ? (
        <Columns hasMobileLayout={true} gap={isBreakpoint ? 52 : 48}>
            <ColumnsItem size={ColumnsSize['1/1']} extraClass="column__item--tablet-cta">
                {textSection}
            </ColumnsItem>
            <ColumnsItem
                size={ColumnsSize['1/1']}
                extraClass="column__item--tablet-cta column__item--form-email-capture"
                justifyContentCenter={true}
            >
                {formSection}
            </ColumnsItem>
        </Columns>
    ) : (
        <>
            <div>{textSection}</div>
            <div style={{ marginTop: isBreakpoint ? 24 : 16 }}>{formSection}</div>
        </>
    );

    return (
        <>
            {formState !== FormState.SUCCESS ? formLayout : null}

            {formState === FormState.SUCCESS ? (
                <div className={classnames.block}>
                    <div className="form__success_notice">
                        <Header1>{lexemes.lxFormSuccessSubmissionText}</Header1>
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default FormEmailCapture;
