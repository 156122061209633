import React, { useEffect, useRef } from 'react';
import { BlockProps } from '../index.types';
import PageSection from 'components/success-stories/page-section/page-section';
import CtaBox, { CtaBoxAlign } from 'components/cta-box/cta-box';
import CtaBoxTitle from 'components/cta-box/cta-box-title';
import CtaBoxMedia, { CtaBoxMediaAlign } from 'components/cta-box/cta-box-media';
import { Header1 } from 'components/typography';
import useMediaQuery, { MediaQueryWidth } from 'lib/use-media-query';
import { Widget } from '@typeform/embed-react';
import * as Scroll from 'react-scroll';
import { clickEventfromOptions, GA4_ID } from 'components/google-analytics/google-analytics';

type BlockPropsType = {
    block: BlockProps;
} & {
    title?: string;
    widgetId?: string;
    hasPadding?: boolean;
};

type IframeSectionType = BlockProps & BlockPropsType;

export default function IframeSectionBlock({ block, ...props }: IframeSectionType) {
    const typeformWrapperRef = useRef<HTMLDivElement>(null);
    const nextQuestionRef = useRef<boolean>(false);
    const isBreakpoint = useMediaQuery(MediaQueryWidth['tablet-md']);
    const Element = Scroll.Element as new (scrollProps: any) => React.Component<any, any, any>;

    useEffect(() => {
        function handleMouseOver() {
            if (nextQuestionRef.current) {
                document.body.classList.add('no-scroll');
            }
        }
        function handleMouseOut() {
            if (nextQuestionRef.current) {
                document.body.classList.remove('no-scroll');
            }
        }

        const wrapper = typeformWrapperRef.current;

        wrapper?.addEventListener('mouseover', handleMouseOver);
        wrapper?.addEventListener('mouseout', handleMouseOut);

        return () => {
            wrapper?.removeEventListener('mouseover', handleMouseOver);
            wrapper?.removeEventListener('mouseout', handleMouseOut);
        };
    }, [typeformWrapperRef, nextQuestionRef]);

    return (
        <PageSection
            bg={PageSection.Background.SEMI_COVERED}
            hasPadding={props.hasPadding !== false}
        >
            <CtaBox align={CtaBoxAlign.CENTERED}>
                <CtaBoxTitle>
                    <Header1 html={props.title} />
                </CtaBoxTitle>

                <>
                    {props.widgetId ? (
                        <CtaBoxMedia
                            fullWidth={true}
                            align={CtaBoxMediaAlign.CENTERED}
                            marginTop={isBreakpoint ? 48 : 72}
                        >
                            <div className="typeform-wrapper" ref={typeformWrapperRef}>
                                <Element name="submit-story-iframe">
                                    <Widget
                                        iframeProps={{ id: 'submit-story' }}
                                        id={props.widgetId}
                                        onQuestionChanged={() => {
                                            clickEventfromOptions({
                                                seoCategory: 'TypeForm Form',
                                                seoAction: 'Answer Question',
                                                seoLabel: 'Form Question',
                                            });
                                            document.body.classList.add('no-scroll');
                                            nextQuestionRef.current = true;
                                        }}
                                        onEndingButtonClick={() => {
                                            clickEventfromOptions({
                                                seoCategory: 'TypeForm Form',
                                                seoAction: 'Submit Form',
                                                seoLabel: 'Form submission',
                                            });
                                        }}
                                        /**
                                         * Tricky one - it will result in error, if no shared instance
                                         */
                                        shareGaInstance={GA4_ID}
                                        style={{
                                            height: '75vh',
                                            width: '100%',
                                        }}
                                    />
                                </Element>
                            </div>
                        </CtaBoxMedia>
                    ) : null}
                </>
            </CtaBox>
        </PageSection>
    );
}
