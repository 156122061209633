import React, { useCallback } from 'react';
import cx from 'classnames';
import Button from 'components/button/button';
import Link from 'components/link/link';

type Props = {
    items?: {
        url?: string;
        icon?: React.ReactNode;
        button?: boolean;
        type?: string;
        labelText?: string;
    }[];
    isForSharing?: boolean;
    isBFF?: boolean;
};

function SocialLinks(this: any, props: Props) {
    const { items, isForSharing } = props;

    const className = cx({
        'social-links': true,
        [`social-links--bff`]: props.isBFF,
    });

    const doShare = useCallback((socialType: any, event: React.MouseEvent) => {
        event.preventDefault();
        const url = encodeURIComponent(global.document.location.href);

        // https://github.com/bradvin/social-share-urls#linkedin
        // images/-/moxie/new/js/components/social.js
        switch (socialType) {
            case 'pinterest':
                popup(`http://www.pinterest.com/pin/create/button/?url=${url}`, 'Pinterest');
                break;
            case 'facebook':
                popup(`https://www.facebook.com/share.php?u=${url}`, 'Facebook');
                break;
            case 'twitter':
                popup(`https://twitter.com/intent/tweet?url=${url}&text=""`, 'Twitter');
                break;
            case 'linkedin':
                popup(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, 'Linkedin');
                break;
            default:
                break;
        }
    }, []);

    // eslint-disable-next-line consistent-this
    const context = this;

    return (
        <div className={className}>
            <ul className="social-links__list">
                {items &&
                    items.map(({ url, icon, button, type, labelText }: any, index: any) =>
                        url ? (
                            <li className="social-links__item" key={`social-links-item-${index}`}>
                                {!button && url ? (
                                    <Link
                                        aria-label={labelText}
                                        className="social-links__link"
                                        href={url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <span className="a11y-visually-hidden">{labelText}</span>
                                        {icon}
                                    </Link>
                                ) : null}

                                {button ? (
                                    <Button
                                        labelText={labelText}
                                        color={Button.color.Primary}
                                        isCircle={true}
                                        extraClass={`is-a11y ${type}`}
                                        jsClass="js-share-button"
                                        href={url}
                                        onClick={
                                            isForSharing ? doShare.bind(context, type) : undefined
                                        }
                                        icon={icon}
                                        text={
                                            <span className="a11y-visually-hidden">
                                                {labelText}
                                            </span>
                                        }
                                    />
                                ) : null}

                                {!button && icon ? (
                                    <div
                                        className="social-links__icon"
                                        onClick={
                                            isForSharing ? doShare.bind(context, type) : undefined
                                        }
                                    >
                                        <span className="a11y-visually-hidden">{labelText}</span>
                                        {icon}
                                    </div>
                                ) : null}
                            </li>
                        ) : null
                    )}
            </ul>
        </div>
    );
}

function popup(url: any, window_title: any) {
    const width = 575;
    const height = 400;
    const left = (global.window.innerWidth - width) / 2;
    const top = (global.window.innerHeight - height) / 2;
    // eslint-disable-next-line no-useless-concat
    const opts = `${'status=1' + ',width='}${width},height=${height},top=${top},left=${left}`;

    global.window.open(url, window_title, opts);
}

export default SocialLinks;
