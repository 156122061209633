import * as React from 'react';
import Text, { TextType, TextProps } from 'components/typography/text';

const SubTitle: React.FC<TextProps> = (props) => (
    <Text type={TextType.SUBTITLE} {...props}>
        {props.children}
    </Text>
);

export default SubTitle;
