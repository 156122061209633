import * as React from 'react';
import PageSection from 'components/page/page-section';
import Grid from 'components/grid/grid';
import MediaBox, { MediaBoxAspect } from 'components/media-box/media-box';
import { Header2, P2, P3 } from 'components/typography';
import { TextTagName } from 'components/typography/text';
import { Maybe, Post } from 'interfaces/bumble-bff-landing-microsite-cms';
import Link from 'components/link/link';

function setDateInLocalisedFormat(date: string) {
    return new Date(date).toLocaleDateString('en', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
}

function setAbsPostUrl(url: string) {
    const absUrl = `bumble.com/bff/friendship/${url}`.replace(/\/$/g, '').replace(/\/\//g, '/');
    return `https://${absUrl}`;
}

type FeedGridProps = {
    title?: string;
    posts?: Maybe<Maybe<Post>[]> | undefined;
};

export function FeedGrid(props: FeedGridProps) {
    return (
        <PageSection inner={true} extraClass="benefit-section benefit-section--blog">
            {props.title ? (
                <div style={{ marginBottom: 32, textAlign: 'center' }}>
                    <Header2 tagname={TextTagName.h2}>
                        <Link href="/bff/friendship" aria-label={props.title}>
                            {props.title}
                        </Link>
                    </Header2>
                </div>
            ) : null}

            <Grid threeColumns={true} extraClass="grid--triple-mini-gap">
                {props.posts?.map((post, idx) =>
                    post?.uri ? (
                        <Link
                            href={setAbsPostUrl(post?.uri)}
                            target="_blank"
                            key={`cta-${idx}`}
                            rel="noreferrer"
                        >
                            {post?.featuredImage?.node?.mediaItemUrl ? (
                                <div>
                                    <MediaBox
                                        src={post?.featuredImage?.node?.mediaItemUrl}
                                        aspect={MediaBoxAspect.SQUARE}
                                    />
                                </div>
                            ) : undefined}
                            {post?.title ? (
                                <div style={{ marginTop: 28 }}>
                                    <P2 html={post?.title} />
                                </div>
                            ) : undefined}
                            {post?.date ? (
                                <div style={{ marginTop: 'auto' }}>
                                    <div style={{ marginTop: 16 }}>
                                        <P3 html={setDateInLocalisedFormat(post.date)} />
                                    </div>
                                </div>
                            ) : undefined}
                        </Link>
                    ) : undefined
                )}
            </Grid>
        </PageSection>
    );
}
